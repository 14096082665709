import axios from 'axios';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import successHandle from '../utils/successHandle';
import errorHandle from '../utils/errorHandle';

const apiEndPoint = process.env.REACT_APP_API_URL + "/api/user/subscribe";
const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const Footer = () => {
	const intialState = { email: '', loading: false }
	const [state, setCompleteState] = useState(intialState)
	const location = useLocation();
	const isAuthPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot'

  if (isAuthPage) {
    return <></>;
  }

  const setState = (newState) =>
    setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const validateEmail = (email) => {
    const isValidEmail = EMAIL_REGEX_PATTERN.test(email);
    return isValidEmail;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    subscribeNewsLetter();
  };

  const subscribeNewsLetter = async () => {
    const { email } = state;
    if (!email) {
      toast.error("Please enter Email", { position: "bottom-right" });
      return;
    }

    const validEmail = validateEmail(email);
    if (!validEmail) {
      toast.error("Invalid Email!", { position: "bottom-right" });
      return;
    }

		setState({ loading: true })
		const data = { email }
		await axios.post(apiEndPoint, data)
			.then((res) => {
				setState({ loading: false });
				successHandle(res?.data?.message);
			})
			.catch((err) => {
				setState({ loading: false })
				errorHandle(err)
			})
	}

	return (
		<div>
			<footer id="footer">
				{location.pathname === '/' ? (
					<div class="footer-newsletter">
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-6">
									<h4>Join Our Newsletter</h4>
									<p>Subscribe our newsletter for getting latest updates on your email</p>
									<form onSubmit={handleSubmit}>
										<input type="email" className='form-control' name="email" value={state?.email} onChange={(e) => setState({ email: e.target.value })} />
										<input type="submit" disabled={state.loading} value="Subscribe" onClick={handleSubmit} />
									</form>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<div class="footer-top">
					<div class="container">
						<div class="row">
							<div class="col-lg-3 col-md-6 footer-contact">
								<h3>Gleamdesk</h3>
								<p>
									A108 Adam Street <br />
									New York, NY 535022<br />
									United States <br /><br />
									<strong>Phone:</strong> +1 5589 55488 55<br />
									<strong>Email:</strong> contact@gleamdesk.com<br />
								</p>
							</div>

							<div class="col-lg-3 col-md-6 footer-links">
								<h4>Useful Links</h4>
								<ul>
									<li><i class="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
									<li><i class="bx bx-chevron-right"></i> <Link to="/about">About us</Link></li>
									<li><i class="bx bx-chevron-right"></i> <Link to="/appointments">appointments</Link></li>
									<li><i class="bx bx-chevron-right"></i> <Link to="/partners">Partners</Link></li>
								</ul>
							</div>

							<div class="col-lg-3 col-md-6 footer-links">
								<h4>Our Services</h4>
								<ul>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Research and Analysis</a></li>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Portfolio Management</a></li>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Stocks</a></li>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Currencies</a></li>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Global market</a></li>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Education and Training</a></li>
									<li><i class="bx bx-chevron-right"></i> <a href="#">Options and Futures Tradings</a></li>
								</ul>
							</div>

							<div class="col-lg-3 col-md-6 footer-links">
								<h4>Our Social Networks</h4>
								<p>Follow us for all the latest updates across our social networks!</p>
								<div class="social-links mt-3">
									<a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
									<a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
									<a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
									<a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
									<a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="container py-4">
					<div class="copyright">
						&copy; Copyright <strong><span>MappingNinja</span></strong>. All Rights Reserved
					</div>
					<div class="credits">
						Designed by <a href="https://mappingninja.solutions" target='_blank'>MappingNinja</a>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer;
