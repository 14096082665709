import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';

const Test = () => {
  const products = [
    {
      "_id": "ZgmUTpDnzKtKcP7I",
      "month": "January",
      "year": "2023",
      "status": "Up",
      "percentage": "0.5",
      "dateTime": "Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:10:58.866Z",
      "updatedAt": "2024-02-17T15:10:58.866Z",
      "__v": 0
    },
    {
      "_id": "QW1mLo40Pv2KwBnA",
      "month": "February",
      "year": "2023",
      "status": "Up",
      "percentage": "4.5",
      "dateTime": "Wed Feb 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:11:09.405Z",
      "updatedAt": "2024-02-17T15:11:09.405Z",
      "__v": 0
    },
    {
      "_id": "eQpD5rAw8alT4sWo",
      "month": "March",
      "year": "2023",
      "status": "Down",
      "percentage": "3.5",
      "dateTime": "Wed Mar 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:11:22.110Z",
      "updatedAt": "2024-02-17T15:11:22.110Z",
      "__v": 0
    },
    {
      "_id": "E0iKmZeIpbberEnN",
      "month": "April",
      "year": "2023",
      "status": "Up",
      "percentage": "5.5",
      "dateTime": "Sat Apr 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:11:58.942Z",
      "updatedAt": "2024-02-17T15:11:58.942Z",
      "__v": 0
    },
    {
      "_id": "dMXcXXlEBf1f3b2Z",
      "month": "May",
      "year": "2023",
      "status": "Up",
      "percentage": "16.5",
      "dateTime": "Mon May 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:12:13.202Z",
      "updatedAt": "2024-02-17T15:12:13.202Z",
      "__v": 0
    },
    {
      "_id": "qKw8plcHioSRVXK1",
      "month": "June",
      "year": "2023",
      "status": "Down",
      "percentage": "13.5",
      "dateTime": "Thu Jun 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:12:24.534Z",
      "updatedAt": "2024-02-17T15:12:24.534Z",
      "__v": 0
    },
    {
      "_id": "uDiq3H0TOlE6vB5w",
      "month": "July",
      "year": "2023",
      "status": "Down",
      "percentage": "10.5",
      "dateTime": "Sat Jul 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:12:39.136Z",
      "updatedAt": "2024-02-17T15:12:39.136Z",
      "__v": 0
    },
    {
      "_id": "S2BxqAaqnurg3H98",
      "month": "August",
      "year": "2023",
      "status": "Down",
      "percentage": "10.5",
      "dateTime": "Tue Aug 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:13:19.401Z",
      "updatedAt": "2024-02-17T15:13:19.401Z",
      "__v": 0
    },
    {
      "_id": "iUSYDNjHLEU0HB7p",
      "month": "September",
      "year": "2023",
      "status": "Up",
      "percentage": "19.5",
      "dateTime": "Fri Sep 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:13:47.275Z",
      "updatedAt": "2024-02-17T15:13:47.275Z",
      "__v": 0
    },
    {
      "_id": "lqu9dCc464kpFlmi",
      "month": "October",
      "year": "2023",
      "status": "Up",
      "percentage": "21.32",
      "dateTime": "Sun Oct 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:13:57.023Z",
      "updatedAt": "2024-02-17T15:13:57.023Z",
      "__v": 0
    },
    {
      "_id": "o5n6xGsXsHOK54g9",
      "month": "November",
      "year": "2023",
      "status": "Up",
      "percentage": "26.58",
      "dateTime": "Wed Nov 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:14:09.680Z",
      "updatedAt": "2024-02-17T15:14:09.680Z",
      "__v": 0
    },
    {
      "_id": "kJQC5rabxnLJW5jp",
      "month": "December",
      "year": "2023",
      "status": "Down",
      "percentage": "24.15",
      "dateTime": "Fri Dec 01 2023 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:14:25.505Z",
      "updatedAt": "2024-02-17T15:14:25.505Z",
      "__v": 0
    },
    {
      "_id": "AuXOtRThyZqQJ6jt",
      "month": "January",
      "year": "2024",
      "status": "Down",
      "percentage": "23.15",
      "dateTime": "Mon Jan 01 2024 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:14:39.029Z",
      "updatedAt": "2024-02-17T15:14:39.029Z",
      "__v": 0
    },
    {
      "_id": "dSJmBvvCNvNluZ36",
      "month": "February",
      "year": "2024",
      "status": "Up",
      "percentage": "28.95",
      "dateTime": "Thu Feb 01 2024 00:00:00 GMT+0530 (India Standard Time)",
      "createdAt": "2024-02-17T15:15:07.532Z",
      "updatedAt": "2024-02-17T15:15:07.532Z",
      "__v": 0
    }
  ]

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];


  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case 'INSTOCK':
        return 'success';

      case 'LOWSTOCK':
        return 'warning';

      case 'OUTOFSTOCK':
        return 'danger';

      default:
        return null;
    }
  };

  const renderPropdesk = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          {/* <img src={`https://primefaces.org/cdn/primereact/images/product/bamboo-watch.jpg`} alt={product.name} className="w-6 shadow-2" /> */}
        </div>
        <div>
          <h4 className="mb-1">{product.month} - {product.year}</h4>
          <h6 className="mt-0 mb-3">${product.percentage}</h6>
          {/* <Tag value={product.inventoryStatus} severity={getSeverity(product)}></Tag> */}
        </div>
      </div>
    );
  };
  return (
    <div className='mt-5 pt-5'>
      test
      <div className="card px-5 mx-5">
        <Carousel
          value={products}
          numVisible={3}
          numScroll={3}
          responsiveOptions={responsiveOptions}
          itemTemplate={renderPropdesk} />
      </div>
    </div>
  )
}

export default Test