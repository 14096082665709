import axios from "axios";
import constants from "../constants/constants";

const { GET_SLOTS, GET_SLOTS_SUCCESS, GET_SLOTS_ERROR } = constants;
const GET_SLOTS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/checkavailable";

export const getSlotsAction = (data, config) => async (dispatch) => {
  dispatch({ type: GET_SLOTS })
  await axios.post(GET_SLOTS_API_END_POINT, data, config)
    .then((res) => dispatch({ type: GET_SLOTS_SUCCESS, slots: res?.data?.slots }))
    .catch((error) => dispatch({ type: GET_SLOTS_ERROR, error }))
}
