import constants from "../constants/constants";
import errorHandle from "../../utils/errorHandle";

const { GET_SLOTS, GET_SLOTS_SUCCESS, GET_SLOTS_ERROR } = constants;
const initialState = { loading: false, slots: [], message: '', error: '' }

export const appointmentsReducer = (state = initialState, action) => {
  const { type, slots, error } = action;
  switch (type) {
    case GET_SLOTS:
      return { loading: true }
    case GET_SLOTS_SUCCESS:
      return { loading: false, slots }
    case GET_SLOTS_ERROR:
      
      return { loading: false, error: errorHandle(error) }
    default:
      return state
  }
}