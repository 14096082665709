import React from 'react'
import moment from 'moment';

const Appointment = ({ appointment }) => {
	return (
		<div className="col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100} >
			<div className="icon-box w-100">
				<div className="icon">
					<i className="fa fa-calendar text-muted" />
				</div>
				<h4 className="title mb-0">{appointment?.title}</h4>
				<small>{moment(appointment?.dateTime).format("dddd, MMMM Do YYYY, h:mm a")}</small>
				<p className="description mt-1">{appointment?.note}</p>
			</div>
		</div>
	)
}

export default Appointment