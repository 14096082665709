import React from 'react'
import CountUp from "react-countup";

const Counters = ({ yourAppointments, totalPartners, totalAppointment, totalUsers }) => {
	return (
		<section id="counts" class="counts section-bg">
			<div class="container aos-init aos-animate" data-aos="fade-up">
				<div class="row py-4">
					<div class="col-lg-3 col-md-6 my-2">
						<div class="count-box">
							<i class="fa fa-users"></i>
							<div>
								<CountUp start={0} end={totalUsers} duration={2} />
								<p>Total Users</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 my-2">
						<div class="count-box">
							<i class="fa fa-calendar" style={{ color: "#ee6c20" }}></i>
							<div>
								<CountUp start={0} end={totalAppointment} duration={2} />
								<p>Total Appointments</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 my-2">
						<div class="count-box">
							<i class="fa fa-calendar" style={{ color: "#15be56" }}></i>
							<div>
								<CountUp start={0} end={yourAppointments} duration={2} />
								<p>your Appointments</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 my-2">
						<div class="count-box">
							<i class="fa fa-handshake-o" style={{ color: "#bb0852" }}></i>
							<div>
								<CountUp start={0} end={totalPartners} duration={2} />
								<p>Total Partners</p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	)
}

export default Counters