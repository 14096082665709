import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authContext from '../context/authContext/authContext';
const EDIT_PROFILE_ENDPOINT = process.env.REACT_APP_API_URL + "/api/user/updateProfile";
const FILE_UPLOAD_ENDPOINT = process.env.REACT_APP_API_URL + "/api/user/upload";

const IMAGE_UPLOAD_ENDPOINT = "https://api.cloudinary.com/v1_1/dnut9ipl0/image/upload"

const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const MOBILE_NUMBER_REGEX_PATTERN = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
const STRONG_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!^%*?&]{8,15}$/;
const PASSWORD_STRENGTH = {
  1: "Very weak",
  2: "Weak",
  3: "Meduim",
  4: "Strong",
}

const EditProfile = () => {
  const navigate = useNavigate()
  const { user, setUser, getConfig, logout } = useContext(authContext);
  const config = getConfig();
  const intialState = { ...user, loading: false }
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const handleFileChange = (files) => {
    if (!(files || []).length) {
      return;
    }

    const file = files[0]
    const fileSize = file?.size * 0.000001
    if (fileSize > 1) {
      toast.error("File size not higer than 1MB!");
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setState({ image: file, imageUrl });
  }

  const imageUrl = "https://cloudinary-marketing-res.cloudinary.com/images/w_1000,c_scale/v1679921049/Image_URL_header/Image_URL_header-png?_i=AA"


  const validateName = () => {
    if (!state.name) {
      setState({ nameError: '*Enter name' });
    }
  }

  const validateEmail = () => {
    const isValidEmail = EMAIL_REGEX_PATTERN.test(state.email);
    if (!isValidEmail) {
      setState({ emailError: '*Please enter a valid email!' })
    }
  }

  const validatePhoneNumber = () => {
    if (!state.phone || !MOBILE_NUMBER_REGEX_PATTERN.test(state.phone)) {
      setState({ phoneError: '*Provide valid indian numbers' })
    }
  }

  const validateAddress = () => {
    if (!state?.address) {
      setState({ addressError: '*Provide valid address!' })
    }
  }

  const checkStrength = (pass) => {
    if (pass.length > 15) {
      setState({ newPasswordError: "*Password is too lengthy", newPasswordStatus: '' })
      return
    }
    else if (pass.length < 8) {
      setState({ newPasswordError: "*Password is too short", newPasswordStatus: '' })
      return;
    }

    if (STRONG_PASSWORD_REGEX.test(pass)) {
      setState({ newPasswordStatus: 'Password is strong', newPasswordError: '' });
      return
    }

    let count = 0;
    let regex1 = /[a-z]/;
    if (regex1.test(pass)) count++;
    let regex2 = /[A-Z]/;
    if (regex2.test(pass)) count++;
    let regex3 = /[\d]/;
    if (regex3.test(pass)) count++;
    let regex4 = /[!@#$%^&*.?]/;
    if (regex4.test(pass)) count++;
    setState({ newPasswordError: '', newPasswordStatus: PASSWORD_STRENGTH[count], })
  }

  useEffect(() => {
    if (!state.newPassword) {
      return;
    }

    checkStrength(state.newPassword)
  }, [state.newPassword]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ invalidError: '' })
    editProfile();
  }

  const editProfile = async () => {
    setState({ loading: true });
    const { name, nameError, phone, phoneError, email, emailError, newPassword, newPasswordError, oldPassword, imageUrl, image, file, address, addressError } = state;
    const isValid = name && email && phone && address && imageUrl && !nameError && !emailError && !phoneError && !addressError && !newPasswordError;

    if (!isValid) {
      setState({ invalidError: "*Please provide all the fileds", loading: false })
      return;
    }

    if (newPassword && !oldPassword) {
      toast.error("Please enter old password!", { position: 'bottom-right' });
      return;
    }

    let fileUrl = imageUrl;
    if (image && typeof (image) !== "string") {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', 'gleamdesk');
      formData.append('cloud_name', 'dnut9ipl0');
      try {
        const { data } = await axios.post(IMAGE_UPLOAD_ENDPOINT, formData);
        fileUrl = data?.url
      } catch (err) {
        toast.error(err?.response?.data?.error || err.message)
        return;
      }
    }

    const update = { name, phone, email, image: fileUrl, oldPassword, newPassword, address }
    await axios.post(EDIT_PROFILE_ENDPOINT, { update }, config)
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data.user))
        setUser(res.data.user);
        toast.success(res.data.message + ", Please login again to continue services!", { position: 'bottom-right' });
        logout();
        navigate('/login');
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error || err.message, { position: 'bottom-right' });
      })
  }
  return (
    <div className='edit-profile-form container mb-5'>
      <div className='container pt-3'>
        <div className='pt-5'>
          <form className='pt-5' onSubmit={handleSubmit}>
            <div className='d-flex align-items-center justify-content-center mb-4'>
              {state?.imageUrl ? <img src={state?.imageUrl} className='img-user' /> : null}
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div class="form-group mb-3">
                  <label className='mb-1' for="exampleInputEmail1">Name</label>
                  <input
                    type="name"
                    class="form-control"
                    id="exampleInputName1"
                    aria-describedby="emailHelp"
                    placeholder="Enter name"
                    value={state?.name}
                    onChange={(e) => setState({ name: e.target.value })}
                    onFocus={() => setState({ nameError: '' })}
                    onBlur={validateName}
                    disabled={state.loading}
                  />
                  {state.nameError ? <small className='text-danger ml-2'>{state.nameError}</small> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-group mb-3">
                  <label className='mb-1' for="exampleInputEmail1">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your contact number"
                    value={state?.phone}
                    onChange={(e) => setState({ phone: e.target.value })}
                    onFocus={() => setState({ phoneError: '' })}
                    onBlur={validatePhoneNumber}
                    disabled={state.loading}
                  />
                  {state.phoneError ? <small className='text-danger ml-2'>{state.phoneError}</small> : null}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div class="form-group mb-3">
                  <label className='mb-1' for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={state?.email}
                    onChange={(e) => setState({ email: e.target.value })}
                    onFocus={() => setState({ emailError: '' })}
                    onBlur={validateEmail}
                    disabled={state.loading}
                  />
                  {state.emailError ? <small className='text-danger ml-2'>{state.emailError}</small> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div class="form-group mb-3">
                  <label className='mb-1' for="exampleInputEmail1">Photo</label>
                  <input
                    type="file"
                    class="form-control"
                    onChange={(e) => handleFileChange(e.target.files)}
                    disabled={state.loading}
                  />
                </div>
              </div>
              <div class="form-group mb-3">
                <label className='mb-1' for="exampleInputEmail1">Address</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter address"
                  value={state?.address}
                  onChange={(e) => setState({ address: e.target.value })}
                  onFocus={() => setState({ addressError: '' })}
                  onBlur={validateAddress}
                  disabled={state.loading}
                />
                {state.addressError ? <small className='text-danger ml-2'>{state.addressError}</small> : null}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-6'>
                <div class="form-group mb-3">
                  <label className='mb-1' for="exampleInputPassword1">Old password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Old password"
                    value={state.oldPassword}
                    onChange={(e) => setState({ oldPassword: e.target.value })}
                    disabled={state.loading}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div class="form-group">
                  <label className='mb-1' for="exampleInputPassword1">New password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="New password"
                    value={state.newPassword}
                    onChange={(e) => setState({ newPassword: e.target.value })}
                    onFocus={() => setState({ newPasswordError: '' })}
                    disabled={state.loading}
                  />
                  {state.newPasswordError ? <small className='text-danger ml-2'>{state.newPasswordError}</small> : null}
                  {state.newPasswordStatus ? <small className='text-info ml-2'>{state.newPasswordStatus}</small> : null}
                </div>
              </div>
            </div>
            <div className=''>
              <button type="submit" class="btn btn-primary w-100" disabled={state.loading} onClick={handleSubmit}>Submit</button>
            </div>
            {state.invalidError ? <div className='text-danger text-center mt-3'>{state.invalidError}</div> : null}
            {state.loading ? (
              <div className='text-center pt-4'>
                <div class="spinner-border text-secondary" role="status" />
              </div>
            ) : null}
          </form>
        </div>

      </div>

    </div>
  )
}

export default EditProfile