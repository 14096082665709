import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Appointment from '../components/homePage/Appointment'
import PropDesk from '../components/homePage/PropDesk'
import Counters from '../components/homePage/Counters'
import StockOfTheMonth from '../components/homePage/StockOfTheMonth'
import AppointMentBooingModal from '../components/modals/AppointMentBooingModal'
import authContext from '../context/authContext/authContext'
import errorHandle from '../utils/errorHandle'
import Partners from '../components/homePage/Partners'

const GET_DASHBOARD_DATA_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/dashboard";

const Home = () => {
	const navigate = useNavigate();
	const { user, getConfig } = useContext(authContext);
	const config = getConfig();

	const intialState = { loading: false, showBookAppointmentModal: false, appointments: [] }
	const [state, setCompleteState] = useState(intialState);
	const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

	const getDashboardData = async () => {
		setState({ loading: true })
		await axios.get(GET_DASHBOARD_DATA_API_END_POINT, config)
			.then((res) => {
				setState({
					...res.data.data,
					propdesks: (res.data.data.propdesks || []).sort((p1, p2) => new Date(p1.dateTime) - new Date(p2.dateTime)),
					loading: false
				})
			})
			.catch((err) => {
				setState({ loading: false })
				errorHandle(err)
			})
	}

	useEffect(() => {
		if (!user) {
			navigate('/login');
		} else {
			getDashboardData();
		}
	}, [])

	return (
		<div className='mt-5'>
			{state.loading ? (
				<div className='pt-5 text-center'>
					<div class="spinner-border text-secondary" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>
			) : null}

			<section id="hero" className="d-flex align-items-center">
				<div className="container">
					<div className="row gy-4">
						<div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
							<h1>Bettter experience with GleamDesk</h1>
							<h2>We are team of talented in stock market making good profit with GleamDesk</h2>
							<div>
								<button className="btn btn-get-started" onClick={() => setState({ showBookAppointmentModal: true })} disabled={state.loading} >Book appointments</button>
							</div>
						</div>
						<div className="col-lg-6 order-1 order-lg-2 hero-img">
							<img src="assets/img/hero-img.svg" className="img-fluid animated" alt="" />
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section id="appointments" className="services section-bg">
					<div className="container" data-aos="fade-up">
						<div className="section-title">
							<h2>Appointments</h2>
							<p>All your appointments to concern with our experts</p>
						</div>
						<div className="row align-items-ecnter justify-content-center">
							{!state.appointments.length ? <p className="text-center">You don't have appointment booked yet</p> : null}
							{(state.appointments || []).map((appointment) => (
								<Appointment colSize={3} appointment={appointment} />
							))}
							<div className='text-center'>
								<button className='btn btn-secondary' disabled={state.loading} onClick={() => navigate('/appointments')}>View all Appointments</button>
							</div>
						</div>
					</div>
				</section>

				<section id="propdesk" className="services section-bg">
					<div className="container" data-aos="fade-up">
						<div className="section-title">
							<h2>Propdesk</h2>
							<p>Check out the Monthly perfomance</p>
						</div>
						<PropDesk propdesks={state.propdesks} />
					</div>
				</section>

				<section id="gdTokens" className="services section-bg">
					<div className="container" data-aos="fade-up">
						<div className="section-title">
							<h2>GD Tokens</h2>
							<p>Here you can get info about GD Tokens</p>
						</div>
						<div className="row align-items-ecnter justify-content-center">
							<div className="col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
								<div className="icon-box">
									<div className="icon">
										<img src="assets/img/gleamDesk.png" style={{ width: '100px' }} />
									</div>
									<p className="description">
										Voluptatum deleniti atque corrupti quos dolores et quas molestias
										excepturi sint occaecati cupiditate
									</p>
									<h4 className="title mt-4">
										<Link to="/gdtokens" className='btn btn-primary text-white'>How To Earn</Link>
									</h4>
								</div>
							</div>
							<div className="col-lg-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
								<div className="icon-box">
									<div className="icon">
										<img src="assets/img/gleamDesk.png" style={{ width: '100px' }} />
									</div>
									<p className="description">
										Voluptatum deleniti atque corrupti quos dolores et quas molestias
										excepturi sint occaecati cupiditate
									</p>
									<h4 className="title mt-4">
										<Link to="gdtokens" className='btn btn-primary text-white'>How To Use</Link>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</section>

				<StockOfTheMonth stock={state.stockOfTheMonth} />

				<Counters
					totalUsers={state.totalUsers}
					totalPartners={state.totalPartners}
					yourAppointments={state.yourAppointments}
					totalAppointment={state.totalAppointment}
				/>

				<section id="clients" className="clients section-bg">
					<div className="container aos-init aos-animate" data-aos="fade-up">
						<div className="section-title">
							<h2>Partners</h2>
							<p>They colaborate with us</p>
						</div>
						<Partners partners={state.partners} />
					</div>
				</section>
			</main>

			<AppointMentBooingModal
				isOpen={state.showBookAppointmentModal}
				toggle={() => setState({ showBookAppointmentModal: false })}
				getDashboardData={getDashboardData}
			/>
		</div >
	)
}

export default Home