import React from 'react'
import moment from 'moment';

const Appointment = ({ appointment, user, isUpComing, isCompleted, deleteAppointment }) => {
  return (
    <div class="col-md-4 my-2">
      <div class="card" style={{ borderColor: '#0097b2' }}>
        <div class="card-body">
          <div className='d-flex align-items-center justify-content-between'>
            <div className='card-title'>
              <i className='fa fa-calendar appointment-card' />
              <strong>{appointment?.title}</strong>
            </div>

            <div className='card-title'>
              {isUpComing ? <div className='badge badge-info badge-pill p-2'>Upcoming</div> : null}
              {isCompleted ? <div className='badge badge-success badge-pill p-2'>Completed</div> : null}
            </div>
          </div>

          <div>{appointment?.note}</div>
          <small className='text-muted fw-500'>{moment(appointment?.dateTime).format("dddd, MMMM Do YYYY, h:mm:ss a")}</small>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='user-name fw-500'>-{user?.name}</div>
            <div>
              <button className='btn btn-sm btn-outline-danger mx-1 my-2' disabled={isCompleted} onClick={() => deleteAppointment(appointment?._id, appointment?.dateTime)}>
                <i className='fa fa-trash' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Appointment