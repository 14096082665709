import React from 'react'

const StockOfTheMonth = ({ stock }) => {
	return (
		<section id="stockOfTheMonth" class="stockOfTheMonth section-bg mb-5">
			<div className="container" data-aos="fade-up">
				<div className="section-title">
					<h2>Stock Of The Month</h2>
					<p>Here you can get info about The best performed stock</p>
				</div>
				<div className="row align-items-ecnter justify-content-center">
					<div className="col-md-5 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
						<img src={stock?.image} alt="stock of month" className='img-stock-month' />
					</div>
					<div className="col-md-6 py-2 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
						<div dangerouslySetInnerHTML={{ __html: stock?.content || '' }} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default StockOfTheMonth