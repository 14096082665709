import axios from "axios";
import constants from "../constants/constants";

const {
  GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR,
} = constants;

const GET_PARTNERS_API_END_POINT = process.env.REACT_APP_API_URL + "/api/admin/getPartners";

export const getPartnersAction = (config) => async (dispatch) => {
  dispatch({ type: GET_PARTNERS })
  await axios.get(GET_PARTNERS_API_END_POINT, config)
    .then((res) => dispatch({ type: GET_PARTNERS_SUCCESS, partners: res?.data?.partners }))
    .catch((error) => dispatch({ type: GET_PARTNERS_ERROR, error }))
}
