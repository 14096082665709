import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../context/authContext/authContext';
import errorHandle from '../utils/errorHandle';
import successHandle from '../utils/successHandle';

const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const SEND_EMAIL_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/contact";

const Contact = () => {
  const { getConfig } = useContext(authContext);

  const intialState = { loading: false, name: '', nameError: '', email: '', emailError: '', subject: '', subjectError: '', message: '', messageError: '', sendEmailError: '' }
  const [state, setCompleteState] = useState(intialState);
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMail();
  }

  const validateName = () => {
    if (!state.name) {
      setState({ nameError: '*Please enter name' })
    }
  }

  const validateEmail = () => {
    const isValidEmail = EMAIL_REGEX_PATTERN.test(state.email);
    if (!isValidEmail) {
      setState({ emailError: '*Please enter a valid email!' })
    }
  }

  const validateSubject = () => {
    if (!state.subject) {
      setState({ subjectError: '*Please enter subject' })
    }
  }

  const validateMessage = () => {
    if (!state.message) {
      setState({ messageError: '*Please enter subject' })
    }
  }

  const sendMail = async () => {
    setState({ loading: true })
    const { name, nameError, email, emailError, subject, subjectError, message, messageError } = state || {}
    const isValid = name && email && subject && message && !nameError && !emailError && !subjectError && !messageError;
    if (!isValid) {
      setState({ sendEmailError: '*Plese fill the correct details', loading: false })
      return;
    }

    const data = { name, email, subject, message };
    await axios.post(SEND_EMAIL_API_END_POINT, data)
      .then((res) => {
        successHandle(res.data.message);
        setState(intialState)
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  return (
    <section id="contact" className="contact mt-5 container" style={{ zoom: 0.8 }}>
      <div className="container mt-5" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-5 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt" />
                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>
              <div className="email">
                <i className="bi bi-envelope" />
                <h4>Email:</h4>
                <p>info@example.com</p>
              </div>
              <div className="phone">
                <i className="bi bi-phone" />
                <h4>Call:</h4>
                <p>+1 5589 55488 55s</p>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7338.766158122134!2d72.04838064431152!3d23.119668916423247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c0301445ae26d%3A0x8030a006b0db014e!2sBelani%20Kiran%20Vijaykumar!5e0!3m2!1sen!2sus!4v1705777565169!5m2!1sen!2sus"
                frameBorder={0}
                style={{ border: 0, width: "100%", height: 290 }}
                allowFullScreen=""
              />
            </div>
          </div>
          <div
            className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <form role="form" className="php-email-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={state.name}
                    onChange={(e) => setState({ name: e.target.value })}
                    onBlur={validateName}
                    onFocus={() => setState({ nameError: '', sendEmailError: '' })}
                    disabled={state.loading}
                  />
                  {state.nameError ? <small className='text-danger ml-2'>{state.nameError}</small> : null}
                </div>
                <div className="form-group col-md-6 mt-3 mt-md-0">
                  <label htmlFor="name">Your Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={state.email}
                    onChange={(e) => setState({ email: e.target.value })}
                    onBlur={validateEmail}
                    onFocus={() => setState({ emailError: '', sendEmailError: '' })}
                    disabled={state.loading}
                  />
                  {state.emailError ? <small className='text-danger ml-2'>{state.emailError}</small> : null}
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="name">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={state.subject}
                  onChange={(e) => setState({ subject: e.target.value })}
                  onBlur={validateSubject}
                  onFocus={() => setState({ subjectError: '', sendEmailError: '' })}
                  disabled={state.loading}
                />
                {state.subjectError ? <small className='text-danger ml-2'>{state.subjectError}</small> : null}
              </div>
              <div className="form-group mt-3">
                <label htmlFor="name">Message</label>
                <textarea
                  className="form-control"
                  rows={10}
                  value={state.message}
                  onChange={(e) => setState({ message: e.target.value })}
                  onBlur={validateMessage}
                  onFocus={() => setState({ messageError: '', sendEmailError: '' })}
                  disabled={state.loading}
                />
                {state.messageError ? <small className='text-danger ml-2'>{state.messageError}</small> : null}
              </div>
              {state.sendEmailError ? <div className='text-center mb-3'><small className='text-danger ml-2'>{state.sendEmailError}</small></div> : null}
              <div className="text-center">
                <button type="submit" onClick={handleSubmit} disabled={state.loading}>Send Message</button>
              </div>
              {state.loading ? (
                <div className='text-center pt-4'>
                  <div class="spinner-border text-secondary" role="status" />
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact