import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import authContext from "../context/authContext/authContext";
import errorHandle from "../utils/errorHandle";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersAction } from "../redux/actions/partnersAction";

const GET_GD_TOKENS_API_END_POINT =
  process.env.REACT_APP_API_URL + "/api/user/getTokens";

const PARTNERS = ["/upstox", "/fd", "/demate", "/mutualfund"];
const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user, logout, getConfig } = useContext(authContext);
  const config = getConfig();

  const getTokens = async () => {
    setState({ loading: true });
    await axios
      .get(GET_GD_TOKENS_API_END_POINT, config)
      .then((res) =>
        setState({ gdTokens: res?.data?.gdTokens, loading: false })
      )
      .catch((error) => errorHandle(error, logout));
  };

  useEffect(() => {
    if (
      !user &&
      pathname !== "/login" &&
      pathname !== "/register" &&
      pathname === "/register"
    ) {
      window.location.href = "/login";
    } else if (user) {
      getTokens();
    }
  }, []);

  const getPartners = () => {
    dispatch(getPartnersAction(config));
  };

  const intialState = {
    isMobileNav: false,
    isPartnerClicked: false,
    isUserOptionsClicked: false,
  };
  const [state, setCompleteState] = useState(intialState);
  const setState = (newState) =>
    setCompleteState((prevState) => ({ ...prevState, ...newState }));
  const { partners, loading } = useSelector((state) => state.partners);
  const isAuthPage =
    pathname === "/login" || pathname === "/register" || pathname === "/forgot";

  useEffect(() => {
    setState(intialState);
  }, [pathname]);

  if (isAuthPage) {
    return <></>;
  }

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const renderClassName = (pathName) => {
    let className = "nav-link";
    if (pathName === pathname) {
      className += " active";
    }
    return className;
  };

  const renderDropdownClass = (pathNames = []) => {
    let className = "nav-link";
    const isPartner = pathNames.includes(pathname);
    if (isPartner) {
      className += " active";
    }

    return className;
  };
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <Link to="/">
            <img
              src="assets/img/gleamDesk.png"
              alt=""
              className="img-fluid nav-logo"
            />
          </Link>
        </div>
        <nav
          id="navbar"
          className={`navbar ${state.isMobileNav ? " navbar-mobile" : ""}`}
        >
          <ul>
            <li>
              <Link className={renderClassName("/")} to="/">
                Home{" "}
              </Link>
            </li>
            <li>
              <Link className={renderClassName("/about")} to="/about">
                About Us
              </Link>
            </li>
            <li>
              <Link
                className={renderClassName("/appointments")}
                to="/appointments"
              >
                Appointents
              </Link>
            </li>
            <li>
              <Link className={renderClassName("/partners")} to="/partners">
                Partners
              </Link>
            </li>
            {/* <li className="dropdown">
							<Link className={renderDropdownClass(PARTNERS)} onClick={() => setState({ isPartnerClicked: !state.isPartnerClicked })}>
								<span>Partners</span> <i className="bi bi-chevron-down" />
							</Link>
							<ul className={(state.isMobileNav && state?.isPartnerClicked) ? 'dropdown-active' : ''}>
								<li>
									<Link className={renderClassName('/partners')} to="/partners">Partners</Link>
								</li>
								<li>
									<Link className={renderClassName('/upstox')} to="/upstox">Upstox</Link>
								</li>
								<li>
									<Link className={renderClassName('/mutualfund')} to="/mutualfund">Mutual fund</Link>
								</li>
								<li>
									<Link className={renderClassName('/fd')} to="/fd">Fd</Link>
								</li>
								<li>
									<Link className={renderClassName('/demate')} to="/demate">Demate</Link>
								</li>
							</ul>
						</li> */}
            <li>
              <Link
                className={`nav-link ${
                  pathname === "/contact" ? "active" : ""
                }`}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="dropdown">
              <Link
                onClick={() =>
                  setState({
                    isUserOptionsClicked: !state.isUserOptionsClicked,
                  })
                }
                className={`nav-link ${
                  pathname === "/profile" || pathname === "/gdtokens"
                    ? "active"
                    : ""
                }`}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <img
                      src={user?.imageUrl}
                      className="img-user-navbar"
                      alt="profile-image"
                    />
                  </div>
                  <div>
                    <span className="user-name">{user?.name}</span>{" "}
                    <i className="bi bi-chevron-down" />
                  </div>
                </div>
              </Link>
              <ul
                className={
                  state.isMobileNav && state?.isUserOptionsClicked
                    ? "dropdown-active"
                    : ""
                }
              >
                <li>
                  <Link
                    to="/gdtokens"
                    className={`nav-link ${
                      pathname === "/gdtokens" ? "active" : ""
                    }`}
                  >
                    Gd tokens: {state?.gdTokens}
                  </Link>
                </li>
                <li>
                  <Link
                    className={`nav-link ${
                      pathname === "/profile" ? "active" : ""
                    }`}
                    to="/profile"
                  >
                    Edit profile
                  </Link>
                </li>
                <li onClick={handleLogout}>
                  <Link className="nav-link btn btn-logout">Logout</Link>
                </li>
              </ul>
            </li>
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={() => setState({ isMobileNav: !state.isMobileNav })}
          />
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
