import constants from "../constants/constants";
import successHandle from "../../utils/successHandle";
import errorHandle from "../../utils/errorHandle";

const {
  GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR,
} = constants;

const initialState = { loading: false, partners: [], error: '' }

export const partnerReducer = (state = initialState, action) => {
  const { type, partners, error } = action;
  switch (type) {
    case GET_PARTNERS:
      return { loading: true }
    case GET_PARTNERS_SUCCESS:
      return { loading: false, partners }
    case GET_PARTNERS_ERROR:
      return { loading: false, error: errorHandle(error) }

    default:
      return state
  }
}