import React from "react";
import Marquee from "react-marquee-slider";

const Partners = ({ partners }) => (
  <Marquee velocity={20} >
    {(partners || []).map((partner) => <img src={partner?.image} key={partner?._id} className="img-partner" />)}
  </Marquee >

);

export default Partners;