import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toastify/dist/ReactToastify.css';

import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Footer from './components/Footer';
import Login from './pages/Login';
import About from './pages/About';
import Register from './pages/Register';
import Appointments from './pages/Appointments';
import Contact from './pages/Contact';
import GdTokens from './pages/GdTokens';
import EditProfile from './pages/EditProfile';
import Partners from './pages/Partners';
import Test from './pages/Test';
import Partner from './pages/Partner.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <NavBar />
      <Routes>
        <Route exact key="home" path="/" element={<Home />} />
        <Route exact key="login" path="/login" element={<Login />} />
        <Route exact key="register" path="/register" element={<Register />} />
        <Route exact key="forgot" path="/forgot" element={<ForgotPassword />} />
        <Route exact key="about" path="/about" element={<About />} />
        <Route exact key="appointments" path="/appointments" element={<Appointments />} />
        <Route exact key="contact" path="/contact" element={<Contact />} />
        <Route exact key="gdTokens" path="/gdtokens" element={<GdTokens />} />
        <Route exact key="profile" path="/profile" element={<EditProfile />} />
        <Route exact key="partners" path="/partners" element={<Partners />} />
        <Route exact key="partner/:id" path="/partner/:id" element={<Partner />} />
        <Route exact key="test" path="/test" element={<Test />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
