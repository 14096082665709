import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import Appointment from '../components/Appointment';
import AppointmentForm from '../components/AppointmentForm';
import authContext from '../context/authContext/authContext';
import errorHandle from '../utils/errorHandle';
import successHandle from '../utils/successHandle';

const GET_APPOINTMENT_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/getAppointments";
const DELETE_APPOINTMENT_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/deleteAppointment";

const Appointments = () => {
  const { user, getConfig } = useContext(authContext);
  const config = getConfig();
  const navigate = useNavigate();

  const intialState = { loading: false, appointments: [] }
  const [state, setCompleteState] = useState(intialState);
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    } else {
      getAppointments();
    }
  }, [user, navigate]);

  const getAppointments = async () => {
    setState({ loading: true })
    await axios.get(GET_APPOINTMENT_API_END_POINT, config)
      .then((res) => {
        const { appointments } = res.data;
        let upComingAppointments = appointments.filter((apointment) => new Date(apointment.dateTime) > new Date());
        upComingAppointments = upComingAppointments.sort((a1, a2) => new Date(a1.dateTime) - new Date(a2.dateTime))
        const completedAppointments = appointments.filter((apointment) => new Date(apointment.dateTime) < new Date());
        setState({ appointments, upComingAppointments, completedAppointments, loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const cofirmDeleteAppointment = async (id) => {
    const data = { id }
    setState({ loading: true })
    await axios.post(DELETE_APPOINTMENT_API_END_POINT, data, config)
      .then((res) => {
        successHandle(res?.data?.message)
        getAppointments();
        setState({ loading: false })
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const deleteAppointment = async (id, date) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        cofirmDeleteAppointment(id, date);
      }
    });
  }

  return (
    <>
      <div className='container'>
        <div className='appointment-form container mt-5 pt-5 mb-5 d-flex align-items-center justify-content-center' >
          <div class="card border-0" >
            <div class="card-body">
              <AppointmentForm isAppointmentPage={true} getAppointments={getAppointments} /><div class="card text-center">
              </div>
            </div>
          </div>
        </div>

        {state.loading ? (
          <div className='text-center'>
            <div class="spinner-border text-secondary" role="status" />
          </div>
        ) : null}

        <div className='mx-5 mb-5'>
          <div class="row">
            {(state?.upComingAppointments || []).map((appointment) => {
              return (
                <Appointment
                  key={appointment?._id}
                  appointment={appointment}
                  user={user}
                  isUpComing={true}
                  deleteAppointment={deleteAppointment}
                />
              )
            })}
          </div>
          <div class="row">
            {(state?.completedAppointments || []).map((appointment) => {
              return (
                <Appointment
                  key={appointment?._id}
                  appointment={appointment}
                  user={user}
                  isCompleted={true}
                  deleteAppointment={deleteAppointment}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Appointments