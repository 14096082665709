
import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';

export default function PropDesk(props) {
  const [propdesks, setPropdesks] = useState(props.propdesks);
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPropdesks(props.propdesks);
    if ((props.propdesks || []).length > 0) {
      setPage(props.propdesks.length - 1)
    }
  }, [props.propdesks]);

  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  const productTemplate = (propdesk) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-4 mt-5 px-3">
        <div className="text-center" data-aos="zoom-in" data-aos-delay={100}>
          <div className="icon-box">
            <div className="icon">
              <i className="bx bxl-dribbble" />
            </div>
            <h4 className="title">
              <a href="">{propdesk.month} - {propdesk.year}</a>
            </h4>
            <p className="description">
              {propdesk.percentage}
              {propdesk.status === 'Up' ? <i className='ml-2 fa fa-arrow-up text-success' /> : <i className='ml-2 fa fa-arrow-down text-danger' />}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card propdesk-card">
      <Carousel
        value={(propdesks || [])}
        numVisible={3}
        numScroll={3}
        responsiveOptions={responsiveOptions}
        itemTemplate={productTemplate}
        page={100}
      />
    </div>
  )
}
