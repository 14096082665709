import { toast } from "react-toastify";

const errorHandle = async (err, callback = async () => {}) => {
  if (err?.response?.status === 401) {
    await callback();
    window.location.href = "/login";
    return;
  }

  toast.error(
    err?.response?.data?.error ||
      err?.message ||
      err ||
      "Something Went Wrong!",
    { position: "bottom-right" }
  );
  return err?.response?.data?.error || err?.message || "Something Went Wrong!";
};

export default errorHandle;
