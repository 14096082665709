import React from 'react'

const About = () => {
  return (
    <div className='container mt-5 pt-5'>
      <div class="container mt-3" data-aos="fade-up" data-aos-delay="500">
        <div className='section-title choose-gleamdesk'>
          <p>About GleamDesk</p>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div>
              <img src="assets/img/about.png" className='img-fluid img-about' alt="" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mt-3 fs-17 fw-500">
              <p>
                Findesk is a group of experienced investors and traders engaged in the stock market since 2019 and are
                consistently making <strong>good profit</strong>.
                Through Findesk we tech, guide and support retail traders and investors to create their own health.
                Starting from risk managementto building a great portfolio, We guide you at everything. At a very
                affordable pricce we offer a great quality stock market education as a platform to open a free demate
                account with an india's leading stock brokers and this offers you a lifetime free membership of a 'Findesk
                Club'.
                Your experience with us can be more exciting than you actually think. So join Findek and start your wealth
                creation journey today.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 mb-5' data-aos="fade-up" data-aos-delay="500">
        <div className='section-title'>
          <p>Core values</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Integrity &amp; transparency</span>
              </div>
            </div>
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>
                  Quality Investment Solutions based on Unbiased Research
                </span>
              </div>
            </div>
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Personalisede Service</span>
              </div>
            </div>
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Monthly P/L</span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Encourage growth &amp; Investment Learning</span>
              </div>
            </div>
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Crystalline Communication</span>
              </div>
            </div>
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Comprehensive &amp; Customized Financial Services</span>
              </div>
            </div>
            <div class="mb-3">
              <div class="card-size">
                <i class="fa fa-circle mr-2 ml-2"></i>
                <span>Weekly portfolio performance</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-4 d-flex align-items-center justify-content-center'>
        <iframe width="90%" height="400" src="https://www.youtube.com/embed/3V-QXD3jeag?si=Ef-371ZK4xikO759" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
      </div>

      <div className='mt-5 mb-5' data-aos="fade-up" data-aos-delay="500">
        <div className='section-title choose-gleamdesk'>
          <p>Why Choose GleamDesk</p>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="zoom-fade-out" data-aos-duration="500">
              <div class="img-box img-box-color">
                <div>
                  <img src="assets/img/about//real-time-maret-info.png" className='' alt="" />
                </div>
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  Real-Time Market information
                </h5>
                <p>
                  We know the value of your Investment and hence help you up with the ongoing updates through our
                  Telegram group throughout the trading hours.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="zoom-fade-out" data-aos-duration="1000">
              <div class="img-box img-box-color">
                <img src="assets/img/about//expert-sector-analysis.png" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  Expert Sector Analyst
                </h5>
                <p>
                  With a team of experts to drive profits to your Investments, LISPL ranges an extensive knack for your
                  Investments.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="zoom-fade-out" data-aos-duration="1500">
              <div class="img-box img-box-color">
                <img src="assets/img/about//research-report.png" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  Wide range of Research Reports
                </h5>
                <p>
                  To let you stay upgraded with all the activities going on around your invested stock and to predict
                  your future Investments, LISPL hash a wide range of research reports for you.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="zoom-fade-out" data-aos-duration="2000">
              <div class="img-box img-box-color">
                <img src="assets/img/about//dedicated-relationship-manager.jfif" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  Dedicated Relationship Managers
                </h5>
                <p>
                  To guide you with any of your Demat &amp; Trading account related queries, our enthusiast team of
                  Relationship Managers is always on the other side of yours telephone.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="box aos-init aos-animate" data-aos="fade-zoom-out" data-aos-duration="500">
              <div class="img-box img-box-color">
                <img src="assets/img/about/customized-market-watchy.webp" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  Customized Market Watch
                </h5>
                <p>
                  Providing everything at your fingertips you being a part of Findesk and get to access to most
                  convenient way to have Customized market watches through our services.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="fade-zoom-out" data-aos-duration="1000">
              <div class="img-box img-box-color">
                <img src="assets/img/about//portfolio-management.png" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  portfolio management service
                </h5>
                <p>
                  Managing your portfolio by yourself when you are busy building your future or having less knowledge at
                  times lacks to get a positive portfolio. Therefore, here at Findesk we care about your time and
                  Investments.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="fade-zoom-out" data-aos-duration="1500">
              <div class="img-box img-box-color">
                <img src="assets/img/about//help-desk.jfif" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  Cetralized Help Desk
                </h5>
                <p>
                  To deliver you quick services with regard to trade understanding or on the technical side, the term at
                  Findesk is always availbale to get you through.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 ">
            <div class="box aos-init aos-animate" data-aos="fade-zoom-out" data-aos-duration="2000">
              <div class="img-box img-box-color">
                <img src="assets/img/about/call-support.jpg" alt="" />
              </div>
              <div class="detail-box">
                <h5 className='fw-700'>
                  call support
                </h5>
                <p>
                  You can also opt for call for support with out team that is basically set to resolve any of your
                  queries.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default About