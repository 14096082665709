import React, { useState } from 'react'
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import authContext from '../context/authContext/authContext';
import { getPartnersAction } from '../redux/actions/partnersAction';

const Partners = () => {
  const intialState = { tab: '' }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getConfig } = useContext(authContext);
  const config = getConfig();
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const { loading, partners } = useSelector((state) => state.partners);

  const getPartners = () => {
    dispatch(getPartnersAction(config))
  }

  useEffect(() => {
    getPartners()
  }, [])

  return (
    <div className='container mt-5'>
      <div className='p-5'>
        <div className='section-title'>
          <p>Partners</p>
          {loading ? (
            <div className='text-center pt-4'>
              <div class="spinner-border text-secondary" role="status" />
            </div>
          ) : null}
          <div className='row partners'>
            {(partners || []).map((partner) => {
              return (
                <div className='col-md-3 my-2'>
                  <div>
                    <img key={partner?._id} onClick={() => navigate('/partner/' + partner._id)} className={`img-fluid h-150 ${state?.tab === partner?._id ? 'border-primary' : ''}`} src={partner?.image} alt={partner?.name} />
                  </div>
                  <div className='partner-name'>{partner?.name}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners