import React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import moment from 'moment';

import errorHandle from '../utils/errorHandle';

const GET_PARTNER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/getPartner";

const Partner = () => {
  const { id } = useParams();

  const intialState = { loading: false, partner: {} }
  const [state, setCompleteState] = useState(intialState);
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const getPartner = async () => {
    setState({ loading: true })
    const data = { id }
    await axios.post(GET_PARTNER_API_END_POINT, data)
      .then((res) => setState({ partner: res.data?.partner, accounts: res.data?.accounts, loading: false }))
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  useEffect(() => {
    getPartner()
  }, [id])

  return (
    <div className='container mt-5 pt-5'>

      {state.loading ? (
        <div className='text-center'>
          <div class="spinner-border text-secondary" role="status" />
        </div>
      ) : null}

      <div className='text-center mb-4'><h2><strong> {state.partner?.name}</strong></h2></div>
      <div className='d-flex align-items-stretch justify-content-center mb-5'>
        <div className='text-center'>
          <img src={state.partner?.image} className='' style={{ height: 300, width: 300 }} />
        </div>
        <div className='ml-5' dangerouslySetInnerHTML={{ __html: state.partner.content }} />
      </div>

      {state.loading ? (
        <div className='text-center'>
          <div class="spinner-border text-secondary" role="status" />
        </div>
      ) : null}

      <div className='mb-5' hidden={!(state.accounts || []).length}>
        <div className='text-center'><h2><strong>Accounts</strong></h2></div>
        <div className='card'>
          <div className='card-body'>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Created</th>
                  <th scope="col">update</th>
                </tr>
              </thead>
              <tbody>
                {(state.accounts || []).map((account, i) => {
                  return (
                    <tr>
                      <th>{i + 1}</th>
                      <td>{account?.name}</td>
                      <td> {moment(account?.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                      <td> {moment(account?.updatedAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partner