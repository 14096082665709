import React, { useContext, useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import 'react-day-picker/dist/style.css';

import authContext from '../context/authContext/authContext';
import { getSlotsAction } from '../redux/actions/appointmentsAction';
import errorHandle from '../utils/errorHandle';

const bookAppointmentEndpoint = process.env.REACT_APP_API_URL + "/api/user/bookAppointment";
const DEFAULT_APPOINTMENTS = ["10", "11", "12", "1", "2", "3", "4", "5", "6"]
const DEFAULT_DATE = moment(new Date()).format("M/D/YYYY")

const AppointmentForm = (props) => {
  const dispatch = useDispatch();
  const { user, getConfig } = useContext(authContext);
  const config = getConfig();

  const initialState = { loading: false, title: '', phone: '', note: '', date: new Date(DEFAULT_DATE), time: '', appointments: [] }
  const [state, setCompleteState] = useState(initialState);
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))
  const { slots, loading } = useSelector((state) => state.appointments)

  useEffect(() => {
    if (props.setState) {
      props.setState({ ...state, bookAppointment: bookAppointment });
    }
  }, [state]);

  const selectDate = (date) => {
    const newDate = moment(new Date()).format("M/D/YYYY")
    if (!date) {
      setState({ date: new Date() });
    } else if (date >= new Date(newDate)) {
      setState({ date });
    } else {
      setState({ appointments: DEFAULT_APPOINTMENTS });
    }

    return;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    bookAppointment();
  };

  const getHours = (time) => {
    if (Number(time) < 10) {
      return Number(time) + 12;
    }

    return time;
  }

  const bookAppointment = async (callback) => {
    setState({ loading: true })
    const { title, phone, note, time, date } = state;
    const dateTime = new Date(date);
    dateTime.setHours(getHours(time));
    const data = { title, phone, note, time, date: date.toDateString(), dateTime: dateTime.toString() }
    await axios.post(bookAppointmentEndpoint, data, config)
      .then((res) => {
        toast.success(res.data.message, { position: 'bottom-right' });
        getSlots();
        setState(initialState)
        if (props.getAppointments) {
          props.getAppointments()
        }

        if (typeof (callback) === 'function') {
          callback();
        }
      })
      .catch((err) => {
        setState({ loading: false })
        errorHandle(err)
      })
  }

  const getSlots = async () => {
    if (!state.date || !user) {
      return;
    }

    const date = new Date(state?.date).toDateString();
    const data = { date };
    dispatch(getSlotsAction(data, config))
  }

  useEffect(() => {
    getSlots();
  }, [state.date]);

  const disabled = (time) => {
    if (!time || loading || state.loading) {
      return true;
    }

    const date = new Date(state.date);
    date.setHours(getHours(time));
    date.setMinutes(0);
    date.setSeconds(0);

    const currentDateTime = new Date();
    currentDateTime.setMinutes(0);
    currentDateTime.setSeconds(0);

    if (date < currentDateTime) {
      return true;
    }

    const isBooked = (slots || []).includes(time);
    if (isBooked) {
      return true;
    }

    return false;
  };

  const selectAppointmentTime = (e, time) => {
    e.preventDefault();
    setState({ time });
  };

  const renderAppointmentSlotClassName = (time) => {
    let className = "w-30 mx-1 my-3 btn btn-outline-dark";
    if (time === state?.time) {
      className += " appointment-selected";
    }

    return className;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div class="form-group mb-4 col-md-6">
          <label className="mb-2" for="formGroupExampleInput">
            Title
          </label>
          <input
            type="text"
            class="form-control"
            id="formGroupExampleInput"
            value={state.title}
            placeholder="Enter your title"
            onChange={(e) => setState({ title: e.target.value })}
          />
        </div>
        <div class="form-group mb-4 col-md-6">
          <label className="mb-2" for="formGroupExampleInput2">
            Contact number
          </label>
          <input
            type="text"
            class="form-control"
            id="formGroupExampleInput2"
            placeholder="Enter your phone number"
            value={state.phone}
            onChange={(e) => setState({ phone: e.target.value })}
          />
        </div>
      </div>

      <div class="form-group">
        <label className="mb-2" for="formGroupExampleInput">
          Additional note
        </label>
        <textarea
          type="text"
          class="form-control"
          id="formGroupExampleInput"
          placeholder="Enter your note regargin the appointment"
          value={state.note}
          onChange={(e) => setState({ note: e.target.value })}
        />
      </div>

      <div className="row">
        <div className="col-md-6 day-picker">
          <DayPicker
            mode="single"
            selected={state.date}
            onSelect={selectDate}
            showOutsideDays
          />
        </div>
        <div className='col-md-6 mt-3 slot-picker'>
          {loading || state.loading ? (
            <div className='text-center pt-4'>
              <div class="spinner-border text-secondary" role="status" />
            </div>
          ) : null}
          <div className='container row'>
            {DEFAULT_APPOINTMENTS.map((time) => {
              return (
                <button
                  disabled={disabled(time)}
                  onClick={(e) => selectAppointmentTime(e, time)}
                  className={renderAppointmentSlotClassName(time)}
                  value={time}
                >
                  {time} : 00{" "}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {props?.isAppointmentPage ? (
        <div className='d-flex align-items-center justify-content-center'>
          <button disabled={state.loading || loading} className='btn btn-primary my-2'>Book appointment</button>
        </div>
      ) : null}

      {loading || state.loading ? (
        <div className='text-center pt-4'>
          <div class="spinner-border text-secondary" role="status" />
        </div>
      ) : null}
    </form>
  );
};

export default AppointmentForm;
