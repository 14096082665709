import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import successHandle from '../utils/successHandle';
import errorHandle from '../utils/errorHandle';

document.querySelector('html body').style.background = '#f6f9fa';

const VERIFY_EMAIL_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/verify/email";
const VERIFY_ANSWER_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/verify/answer";
const CHANGE_PASSWORD_API_END_POINT = process.env.REACT_APP_API_URL + "/api/user/changepassword";

const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const STRONG_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!^%*?&]{8,15}$/;
const PASSWORD_STRENGTH = {
  1: "Very weak",
  2: "Weak",
  3: "Meduim",
  4: "Strong",
}

const ForgotPassword = () => {
  const intialState = {
    loading: false,
    email: '',
    emailError: '',
    answer: '',
    answerError: '',
    password: '',
    passwordError: '',
    passwordStatus: '',
    ForgotPasswordError: ''
  }

  const navigate = useNavigate();
  const [state, setCompleteState] = useState(intialState)
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }))

  const onFocusEmail = () => setState({ emailError: '', ForgotPasswordError: '' })

  const validateEmail = () => {
    const isValidEmail = EMAIL_REGEX_PATTERN.test(state.email);
    if (!isValidEmail) {
      setState({ emailError: '*Please enter a valid email!' })
    }
  }

  const onFocusAnswer = () => setState({ answerError: '', ForgotPasswordError: '' })

  const validateAnswer = () => {
    if (!state.answer) {
      setState({ answerError: '*Enter your answer!' })
    }
  }

  const checkStrength = (pass) => {
    if (pass.length > 15) {
      setState({ passwordError: "*Password is too lengthy" })
      return
    }
    else if (pass.length < 8) {
      setState({ passwordError: "*Password is too short" })
      return;
    }

    if (STRONG_PASSWORD_REGEX.test(pass)) {
      setState({ passwordStatus: 'Password is strong' });
    }

    let count = 0;
    let regex1 = /[a-z]/;
    if (regex1.test(pass)) count++;
    let regex2 = /[A-Z]/;
    if (regex2.test(pass)) count++;
    let regex3 = /[\d]/;
    if (regex3.test(pass)) count++;
    let regex4 = /[!@#$%^&*.?]/;
    if (regex4.test(pass)) count++;
    setState({ passwordError: '', passwordStatus: PASSWORD_STRENGTH[count] })
  }

  useEffect(() => {
    if (!state.password) {
      return;
    }

    checkStrength(state.password)
  }, [state.password]);

  const onFocusPassword = () => setState({ passwordError: '', passwordStatus: '', ForgotPasswordError: '' });

  const validatePassword = () => {
    if (!state.password) {
      setState({ passwordError: '*Please Enter a valid password!' })
    }
  }


  const verifyEmail = async (e) => {
    e.preventDefault()
    setState({ loading: true })
    const isValid = !state.emailError && state.email;
    if (!isValid) {
      setState({ emailError: 'Please enter a valid email!', loading: false })
      return;
    }

    const data = { email: state?.email }
    await axios.post(VERIFY_EMAIL_API_END_POINT, data)
      .then((res) => {
        const { message, valid } = res.data;
        successHandle(message)
        setState({ loading: false, emailVerified: valid })
      })
      .catch((error) => {
        errorHandle(error);
        setState({ loading: false });
      })
  }

  const verifyAnswer = async (e) => {
    e.preventDefault();
    setState({ loading: true })
    const isValid = !state.emailError && state.email && !state.answerError && state.answer;
    if (!isValid) {
      setState({ answerError: 'Please enter a valid details!', loading: false })
      return;
    }

    const data = { email: state?.email, answer: state?.answer }
    await axios.post(VERIFY_ANSWER_API_END_POINT, data)
      .then((res) => {
        const { message, valid } = res.data;
        successHandle(message)
        setState({ loading: false, answerVerified: valid })
      })
      .catch((error) => {
        errorHandle(error);
        setState({ loading: false });
      })
  }

  const changePassword = async (e) => {
    e.preventDefault();
    setState({ loading: true })
    const isValid = !state.emailError && state.email && !state.answerError && state.answer && !state.passwordError && state.password;
    if (!isValid) {
      setState({ answerError: 'Please enter a valid details!', loading: false })
      return;
    }

    const data = { email: state?.email, answer: state?.answer, password: state?.password }
    await axios.post(CHANGE_PASSWORD_API_END_POINT, data)
      .then((res) => {
        const { message } = res.data;
        setState({ loading: false });
        successHandle(message);
        navigate('/login');
      })
      .catch((error) => {
        errorHandle(error);
        setState({ loading: false });
      })
  }


  return (
    <div className='mt-4 gleamDesk-auth'>
      <div class="container d-flex justify-content-center align-items-center">
        <div class="page-login">
          <div class="text-center">
            <a class="navbar-brand mb-4 h1" href="login.html">
              <img src="assets/img/gleamDesk.png" style={{ height: '8.5rem' }} class="navbar-brand-image d-inline-block align-top mr-2" alt="" />
            </a>
          </div>

          <div class="card mb-0">
            <div class="card-body">
              {!state?.emailVerified ? (
                <form id="validate-email-form" onSubmit={verifyEmail}>
                  <div class="form-group mb-3">
                    <label for="exampleDropdownFormEmail1" class="form-label">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleDropdownFormEmail1"
                      placeholder="email@example.com"
                      value={state.email}
                      onChange={(e) => setState({ email: e.target.value })}
                      onFocus={onFocusEmail}
                      onBlur={validateEmail}
                      disabled={state.loading}
                    />
                    {state.emailError ? <small className='text-danger ml-2'>{state.emailError}</small> : null}
                  </div>
                  {state.loading ? (
                    <div className='pb-3 text-center'>
                      <div class="spinner-border text-secondary" role="status" />
                    </div>
                  ) : null}
                  <button type="submit" class="btn btn-sm btn-block btn-primary w-100" onClick={verifyEmail} disabled={state.loading}>Verify email</button>
                  {state.registerError ? <div className='text-center fw-500 mt-2'><small className='text-danger'>{state.registerError}</small></div> : null}

                </form>
              ) : null}

              {state.emailVerified && !state.answerVerified ? (
                <form id="validate-answer-form" onSubmit={verifyAnswer}>
                  <div class="form-group mb-3">
                    <label for="exampleDropdownFormEmail1" class="form-label">What is your first nickname?</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleDropdownFormtext1"
                      placeholder="gleamdesk"
                      value={state.answer}
                      onChange={(e) => setState({ answer: e.target.value })}
                      onFocus={onFocusAnswer}
                      onBlur={validateAnswer}
                      disabled={state.loading}
                    />
                    {state.answerError ? <small className='text-danger ml-2'>{state.answerError}</small> : null}
                  </div>
                  {state.loading ? (
                    <div className='pb-3 text-center'>
                      <div class="spinner-border text-secondary" role="status" />
                    </div>
                  ) : null}
                  <button type="submit" class="btn btn-sm btn-block btn-primary w-100" disabled={state.loading} onClick={verifyAnswer}>Verify answer</button>
                  {state.registerError ? <div className='text-center fw-500 mt-2'><small className='text-danger'>{state.registerError}</small></div> : null}
                </form>
              ) : null}

              {state.emailVerified && state.answerVerified ? (
                <form id="validate-password-form" onSubmit={changePassword}>
                  <div class="form-group mb-3">
                    <label for="exampleDropdownFormEmail1" class="form-label">Enter new password?</label>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleDropdownFormtext1"
                      placeholder="Password"
                      value={state.password}
                      onChange={(e) => setState({ password: e.target.value })}
                      onFocus={onFocusPassword}
                      onBlur={validatePassword}
                      disabled={state.loading}
                    />
                    {state.passwordError ? <small className='text-danger ml-2'>{state.passwordError}</small> : null}
                    {state.passwordStatus ? <small className='text-info ml-2'>{state.passwordStatus}</small> : null}
                  </div>
                  {state.loading ? (
                    <div className='pb-3 text-center'>
                      <div class="spinner-border text-secondary" role="status" />
                    </div>
                  ) : null}
                  <button type="submit" class="btn btn-sm btn-block btn-primary w-100" disabled={state.loading} onClick={changePassword}>Change password</button>
                  {state.registerError ? <div className='text-center fw-500 mt-2'><small className='text-danger'>{state.registerError}</small></div> : null}
                </form>
              ) : null}
            </div>
            <div class="card-footer text-center">
              <Link to="/login"><small>Login</small></Link>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default ForgotPassword;
