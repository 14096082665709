const constants = {
  GET_PARTNERS: 'GET_PARTNERS',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  GET_PARTNERS_ERROR: 'GET_PARTNERS_ERROR',

  GET_SLOTS: 'GET_SLOTS',
  GET_SLOTS_SUCCESS: 'GET_SLOTS_SUCCESS',
  GET_SLOTS_ERROR: 'GET_SLOTS_ERROR'
}

module.exports = constants;