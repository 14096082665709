import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useState } from 'react'

import AppointmentForm from '../AppointmentForm';

const AppointMentBooingModal = ({ isOpen, toggle, getDashboardData }) => {
  const [state, setCompleteState] = useState({});
  const setState = (newState) => setCompleteState((prevState) => ({ ...prevState, ...newState }));

  const handleSubmit = (e) => {
    e.preventDefault();
    state.bookAppointment(getDashboardData);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Book Appointment</ModalHeader>
      <ModalBody className='px-4'>
        <AppointmentForm setState={setState} />
      </ModalBody>
      <ModalFooter className='d-flex align-items-center justify-content-between'>
        <button className='btn btn-secondary' onClick={toggle}>Cancel</button>
        <button className='btn btn-primary' onClick={handleSubmit}>Book appointment</button>
      </ModalFooter>
    </Modal >
  )
}

export default AppointMentBooingModal